import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown/with-html";
import axios from "axios";
import Layout from "../components/Layout";
import Loader from 'react-loader-spinner';


import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';


class CodeBlock extends React.PureComponent {
  render() {
    const { language, value } = this.props;

    return (
      <SyntaxHighlighter language={language} style={atomOneDark}>
        {value}
      </SyntaxHighlighter>
    );
  }
}

function BlogDetails(props) {
  const [content, setContent] = useState("");
  const blogId = props.match.params.id;
  const blogFile = props.match.params.title;

  useEffect(() => {
    axios
      .get(`https://api.jeremyrajan.com/posts?slug=${blogFile}&_limit=1`)
      .then(({ data }) => {
        setContent(data[0]);
      })
      .catch(err => console.log(err));
  }, []);

  // const disqusShortname = "chester-react"; //found in your Disqus.com dashboard
  // const disqusConfig = {
  //   url: "https://tf-react-chester.now.sh/", //Homepage link of this site.
  //   identifier: blogId,
  //   title: blogFile
  // };

  if (!content) {
    return(
      <div className="loader">
        <Loader type="Bars" color="#00BFFF" height={40} width={80} />
      </div>
     );
  }

  return (
    <Layout>
      <div className="mi-blog-details mi-section mi-padding-bottom">
        <div className="container">
          <div className="blog-body">
            <img src={content.image.url} alt={content.title} />
            <ReactMarkdown source={content.body} escapeHtml={false} renderers={{
              code: CodeBlock
            }}></ReactMarkdown>
          </div>
          {/* <div className="mi-blog-details-comments mt-30">
            <Disqus.DiscussionEmbed
              shortname={disqusShortname}
              config={disqusConfig}
            />
          </div> */}
        </div>
      </div>
    </Layout>
  );
}

export default BlogDetails;
