import Mock from "../mock";

const database = {
  information: {
    name: 'Jeremy Rajan',
    aboutContent: "I am a full stack developer, with years of experience in developing high performance applications. Currently leading a team of great engineers with companies engaging in transformation projects",
    // age: 24,
    phone: '',
    // nationality: 'Indian',
    // language: 'English',
    email: '',
    // address: '121 King Street, Melbourne, Australia',
    // freelanceStatus: 'Available',
    socialLinks: {
      // facebook: 'https://facebook.com',
      twitter: 'https://twitter.com/jeremyrajan',
      pinterest: '',
      behance: '',
      linkedin: 'https://www.linkedin.com/in/jeremyrajan/',
      dribbble: '',
      github: 'https://github.com/jeremyrajan'
    },
    brandImage: 'https://res.cloudinary.com/jeremyrajan/image/upload/v1588657226/jeremyrajan.png',
    aboutImage: '/images/about-image.jpg',
    aboutImageLg: '/images/about-image-lg.jpg',
    cvfile: '/files/empty.pdf'
  },
  blogs: [
    {
      id: 1,
      title: 'Markdown & Html supported blog.',
      featuredImage: '/images/blog-image-1.jpg',
      filesource: '../../blog/markdown-html-supported-blog.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    }
  ],
  contactInfo: {
    // phoneNumbers: ['+012-3456-7891', '+012-3456-7892'],
    emailAddress: ['jeremyrajan[at]gmail[dot]com'],
    // address: "121 King Street, Melbourne, Victoria 3000, Australia"
  }
}


Mock.onGet("/api/information").reply(config => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply(config => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply(config => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});
