import React from "react";
import { Link } from "react-router-dom";

function Blog(props) {
  const { id, image, title, slug, created_at, body } = props.data;
  const date = new Date(created_at);
  return (
    <div className="mi-blog">
      <div className="mi-blog-image">
        <Link to={`blog/${slug}`}>
          <img src={image.url} alt={title} />
        </Link>
        <div className="mi-blog-date">
          <span className="date">{date.getDate()}</span>
          <span className="month">{date.getMonth()}</span>
        </div>
      </div>
      <div className="mi-blog-content">
        <h5>
          <Link to={`blog/${slug}`}>
            {title}
          </Link>
        </h5>
      </div>
    </div>
  );
}

export default Blog;
