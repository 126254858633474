import React, { Component } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import Layout from '../components/Layout';
import LineIcon from 'react-lineicons';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default class MyApp extends Component {
  state = {
    numPages: null,
    pageNumber: 1,
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  }

  goToPage(move = 'next') {
    let pageNumber = this.state.pageNumber;
    if (move === 'next') {
      pageNumber = pageNumber + 1;
    } else {
      pageNumber = pageNumber - 1;
    }

    if (pageNumber < 1) {
      pageNumber = 1;
    }

    if (pageNumber > this.state.numPages) {
      pageNumber = 1
    }

    this.setState({ pageNumber });
  }

  render() {
    const { pageNumber, numPages } = this.state;

    return (
      <Layout>
        {/* <p>Page {pageNumber} of {numPages}</p> */}
        <div className="mi-resume">
          {/* <button className="mi-button" onClick={_ => this.goToPage('previous')}><LineIcon name="chevron-left"/></button> */}
          <Document
            file="/files/JeremyRajanResume.pdf"
            onLoadSuccess={this.onDocumentLoadSuccess}
          >
            <Page pageNumber={1} />
            <Page pageNumber={2} />
            <Page pageNumber={3} />
          </Document>
          {/* <button className="mi-button" onClick={_ => this.goToPage('next')}><LineIcon name="chevron-right"/></button> */}
        </div>
      </Layout>
    );
  }
}